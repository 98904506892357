import './App.css';
import Layout from "./components/layout";
import About from "./components/About";
import Footer from "./components/Footer";
import linkedIn from './assets/LinkedIn.png';
import github from './assets/GitHub_Logo.png';

function App() {
  return (
    <div className="App">
    <Layout>

      <div className="Hero" id="home">
        <div className="HeroGroup">
          <h1 id="name">clarke roche</h1>
          <p id="roles">developer</p>
          <a href="mailto: clarkeroche@gmail.com" target="_blank" rel="noreferrer" id="heroLink">contact me</a>
        </div>
      </div>

      <About
        title="about me"
        text="My name is Clarke and I currently work as a consultant in Chicago. I have a background as a developer and experience as a business analyst.
        I am always looking for creative outlets and I love to search for the intersection of art and computer science."
      />



      <Footer
        image={linkedIn}
        secondImage={github}
      />

    </Layout>

    </div>
  );
}

export default App;
