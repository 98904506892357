import React from 'react'
import './About.css'

const About = props => (

  <section id="about">

      <article>
        <h3 className="SectionTitle">{props.title}</h3>
        <p className="SectionText">{props.text}</p>
      </article>

  </section>

)

export default About
