import React from 'react'
import './Footer.css'

const Footer = props => (

  <section id="footer">
    <div className="footerWrapper">
        <a href="https://www.linkedin.com/in/clarke-roche-12763396/">
          <img className="image" src={props.image} alt=" " />
        </a>
          <a href="https:/www.github.com/clarkeclarkeclarke">
          <img className="secondImage" src={props.secondImage} alt=" "/>
        </a>
    </div>
  </section>

)

export default Footer
